import React from 'react';


export default function Privacy(props) {

    return (
        <div className="privacy-policy">
            <h1>Privacy Policy</h1>
            <p>Welcome to campsitetext.com.Your privacy is important to us, and we are committed to protecting the personal information you share with us during your use of our services, website, features, content, and applications. This Privacy Policy explains what information we collect, how we use it, and your choices regarding your information.</p>

            <section>
                <h2>Information Collection and Use:</h2>
                <p>
                    We collect information you provide directly to us, such as when you create an account, subscribe to our text message notifications, or participate in any interactive features on the website. The types of information we may collect include:
                    Your name, email address, and phone number
                    <ul>
                        <li>
                        Preferences and interests related to camping
                        </li>
                        <li>
                        Any other information that you choose to provide
                        </li>
                        <li>
                        Preferences and interests related to camping
                        </li>
                    </ul>
                    We use this information to:
                    Provide, maintain, and improve our services
                    Send you information including confirmations, technical notices, updates, security alerts, and support messages
                    Respond to your comments, questions, and requests
                </p>
            </section>

            <section>
                <h2>Text Messaging:</h2>
                {/* Text messaging details here */}
                <p>
                When you opt-in to our text message service, we collect your mobile phone number in order to send you camping-related updates and information. By subscribing to our text message service, you agree to receive text message notifications from campsitetext.com as described in the frequency guidelines of our messaging program.
                </p>
            </section>

            <section>
                <h2>Non-Sharing of Mobile Numbers:</h2>
                {/* Non-sharing of mobile numbers details here */}
                <p>We value your privacy and the trust you place in us. We will not share, sell, rent, or otherwise disclose your mobile number to any third parties for their marketing purposes without your consent. Your mobile number will only be used for the specific purposes of our text messaging service.</p>
            </section>

            <section>
                <h2>Message Frequency:</h2>
                {/* Message frequency details here */}
                <p>The frequency of messages sent through our text message service may vary. You will typically receive messages that relate to upcoming camping site notifications, important notifications, and alerts. </p>
            </section>

            <section>
                <h2>Message and Data Rates:</h2>
                {/* Message and data rates details here */}
                <p>
                Please be aware that message and data rates may apply for any messages sent to you from us and to us from you. If you have any questions about your text plan or data plan, please contact your wireless provider.
                </p>
            </section>

            <section>
                <h2>Your Choices:</h2>
                {/* Your choices details here */}
                <p>You may opt-out of receiving text messages from campsitetext.com at any time by replying "STOP". You can also login and delete all notifications from the website</p>
            </section>

            <section>
                <h2>Changes to this Policy:</h2>
                {/* Changes to the policy details here */}
                <p>We may update this Privacy Policy from time to time.</p>
            </section>

            <section>
                <h2>Contact Us:</h2>
                {/* Contact details here */}
                <p>If you have any questions about this Privacy Policy or our text message service, please contact us at support@campsitetext.com.</p>
            </section>
        </div>
    );
};
