import React from 'react';
import './App.css';
import Amplify from 'aws-amplify';
import { AmplifyAuthenticator, AmplifyGoogleButton, AmplifySignUp, AmplifyContainer, AmplifySignIn } from '@aws-amplify/ui-react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import awsconfig from './aws-exports';
import { createMuiTheme, ThemeProvider, makeStyles, responsiveFontSizes } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline'
import purple from '@material-ui/core/colors/purple';
import green from '@material-ui/core/colors/green';
import MainPage from './MainPage';
import Snackbar from '@material-ui/core/Snackbar';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Auth } from 'aws-amplify';
import ReactGA from 'react-ga';
import Donate  from './Donate';
let theme = createMuiTheme({
  palette: {
    primary: {
      main: purple[500],
    },
    secondary: {
      main: green[500],
    },
  },
});

const useStyles = makeStyles((theme) => ({
  card2: {
    position: 'relative',
    margin: '5% 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    maxHeight: 500,
  },
  gbutton: {
    width: 300,
    marginLeft: "auto",
    marginRight: "auto",
    paddingTop: 20
  }
}));

theme = responsiveFontSizes(theme);
//awsconfig.oauth = {}
awsconfig.oauth.redirectSignIn = `${window.location.origin}/`;
awsconfig.oauth.redirectSignOut = `${window.location.origin}/`;

Amplify.configure(awsconfig);
ReactGA.initialize('UA-201452247-1', { debug: false });
//15300612052-bk22s6405fpjcts5fsgk93ktvaehl2ja.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Fcamptext5afd530f-5afd530f-dev.auth.us-west-2.amazoncognito.com%2Foauth2%2Fidpresponse&scope=openid%20email%20profile&response_type=code&state=ZXlKMWMyVnlVRzl2YkVsa0lqb2lkWE10ZDJWemRDMHlYM05xUmtsb1NHeDJieUlzSW5CeWIzWnBaR1Z5VG1GdFpTSTZJa2R2YjJkc1pTSXNJbU5zYVdWdWRFbGtJam9pTTNaMk5HcHRjakExYUROMlp6ZGlNWFYwWm1weFlUSnJOMmdpTENKeVpXUnBjbVZqZEZWU1NTSTZJbWgwZEhBNkx5OXNiMk5oYkdodmMzUTZNekF3TUM4aUxDSnlaWE53YjI1elpWUjVjR1VpT2lKamIyUmxJaXdpY0hKdmRtbGtaWEpVZVhCbElqb2lSMjl2WjJ4bElpd2ljMk52Y0dWeklqcGJJbUYzY3k1amIyZHVhWFJ2TG5OcFoyNXBiaTUxYzJWeUxtRmtiV2x1SWl3aVpXMWhhV3dpTENKdmNHVnVhV1FpTENKd2FHOXVaU0lzSW5CeWIyWnBiR1VpWFN3aWMzUmhkR1VpT201MWJHd3NJbU52WkdWRGFHRnNiR1Z1WjJVaU9tNTFiR3dzSW1OdlpHVkRhR0ZzYkdWdVoyVk5aWFJvYjJRaU9tNTFiR3dzSW01dmJtTmxJam9pYmtKR2VsZE9VMkZDU20xdVptcFBkMUl4YzJ0RFpWTTNSRkpPYmt0YVNsYzNaVEJ5U1hCaFV6TktXR000Y1VWQ2JIQk9ZV3huVFhOSlVrNHRlRU5WVGpSZlJFSjRhMFU1U205VVIwUlVMUzFHWTI0NWFraHVibE5CVVVobFpHbHVZVEpSZFUxRWVtdHhkRVJQT0U1WlJHcGhjMWxNTldreFkxSlZjMUpyY0ZkamVtOXRPVEpWVkMxUVVVTnRhbFJtVHpWaFRubHlRMGh1VFZWc2NFWkZXbk4wTXpCbUxUUXRVV2hySWl3aWMyVnlkbVZ5U0c5emRGQnZjblFpT2lKallXMXdkR1Y0ZERWaFptUTFNekJtTFRWaFptUTFNekJtTFdSbGRpNWhkWFJvTG5WekxYZGxjM1F0TWk1aGJXRjZiMjVqYjJkdWFYUnZMbU52YlNJc0ltTnlaV0YwYVc5dVZHbHRaVk5sWTI5dVpITWlPakUyTWpFd09UUXdNeklzSW5ObGMzTnBiMjRpT201MWJHd3NJblZ6WlhKQmRIUnlhV0oxZEdWeklqcHVkV3hzTENKemRHRjBaVVp2Y2t4cGJtdHBibWRUWlhOemFXOXVJanBtWVd4elpYMD06WnhzbmN4YU5hQ0FsQktJbDZBVFlwNlVnbnZ
// const federated = {
//   googleClientId: "15300612052-bk22s6405fpjcts5fsgk93ktvaehl2ja.apps.googleusercontent.com",
// }
var hidden = false
const AuthStateApp = () => {
  const [authState, setAuthState] = React.useState();
  const [user, setUser] = React.useState();
  const classes = useStyles();

  function hideAWS() {
    if (hidden) return
    let s11 = document.querySelector("amplify-sign-in")
    if (!s11) return
    let s1 = s11.shadowRoot
    if (s1) {
      let s21 = s1.querySelector("amplify-federated-buttons")
      if (!s21) return
      let s2 = s21.shadowRoot
      if (s2) {
        let s31 = s2.querySelector("amplify-oauth-button")
        if (!s31) return
        let s3 = s31.style.display = "none";
        hidden = true
      }
    }
  }
  const [snack, setSnack] = React.useState(true)
  function onCloseSnack() {
    setSnack(false)
  }
  // function signGoogle() {
  //   Auth.federatedSignIn({ provider: "Google" });
  //   // setTimeout(function () {
  //   //   window.location.reload()
  //   // })
  // }
  React.useEffect(() => {
    ReactGA.pageview(window.location.pathname); 
    window.setInterval(hideAWS, 300);
    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      setUser(authData)
    });
  }, []);
  return authState === AuthState.SignedIn && user ? (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="App">
        <MainPage />
      </div>
    </ThemeProvider>

  ) : (
    <>
      <ThemeProvider theme={theme}>
        <Snackbar
        autoHideDuration={5000}
          open={snack}
          onClose={onCloseSnack}
          message="This site is in Beta, Use at your own discretion. BETA is limited to 50 signups every day. Email support@campsitetext.com"
        />
        <Grid container direction="column" justify="center"
          alignItems="center"
        >
          <Grid item xs={6}>
          <Donate full={true} />

            <img className="float" src="/msgpic.png" alt="logo" />
            <div className="center">
              <Typography alight="center" color="secondary" variant="h5">
                Get instant notification when campsites become available</Typography>
            </div>
          </Grid>

          <Grid item xs={6}>
            <div className={classes.card2}>
              <AmplifyContainer>

                <AmplifyAuthenticator usernameAlias="email">
                  <AmplifySignUp
                    slot="sign-up"
                    usernameAlias="email"
                    formFields={[
                      {
                        type: "email",
                        required: true,
                      },
                      {
                        type: "password",

                        required: true,
                      },
                      {
                        type: "name",
                        label: "Name",
                        placeholder: "Enter Name",
                        required: false,
                      },
                    ]}
                  />
                  <AmplifySignIn slot="sign-in" usernameAlias="email">

                    <AmplifyGoogleButton
                      slot="federated-buttons"
                      onClick={() => Auth.federatedSignIn({ provider: "Google" })}
                    />
                  </AmplifySignIn>
                  {/* <AmplifySignIn slot="sign-in" federated={federated} usernameAlias="email" /> */}

                  {/* <AmplifySignIn slot="sign-in" usernameAlias="email" hideSignUp/> */}
                </AmplifyAuthenticator>
              </AmplifyContainer>

            </div>
          </Grid>

        </Grid>
      </ThemeProvider>

    </>
  );
}

export default AuthStateApp;