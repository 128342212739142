/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const subscribeToMyCamsite = /* GraphQL */ `
  subscription SubscribeToMyCamsite($user: String) {
    subscribeToMyCamsite(user: $user) {
      id
      user
      phone
      createdAt
      updatedAt
      name
      CampsiteID
      loadBucket
      startdate
      enddate
      nights
      content
      ttl
      hits
      dates
      Campsite {
        id
        type
        name
        content
        state
        popular
        hits
        loadBucket
        location
        Rating
        createdAt
        updatedAt
        MultiBoard {
          nextToken
        }
      }
    }
  }
`;
export const subscribeToMyCamsiteDel = /* GraphQL */ `
  subscription SubscribeToMyCamsiteDel($user: String) {
    subscribeToMyCamsiteDel(user: $user) {
      id
      user
      phone
      createdAt
      updatedAt
      name
      CampsiteID
      loadBucket
      startdate
      enddate
      nights
      content
      ttl
      hits
      dates
      Campsite {
        id
        type
        name
        content
        state
        popular
        hits
        loadBucket
        location
        Rating
        createdAt
        updatedAt
        MultiBoard {
          nextToken
        }
      }
    }
  }
`;
export const subscribeToMyFoundResult = /* GraphQL */ `
  subscription SubscribeToMyFoundResult($user: String) {
    subscribeToMyFoundResult(user: $user) {
      id
      user
      content
      ttl
      count
      createdAt
      updatedAt
      CSID
      Campsite {
        id
        type
        name
        content
        state
        popular
        hits
        loadBucket
        location
        Rating
        createdAt
        updatedAt
        MultiBoard {
          nextToken
        }
      }
    }
  }
`;
export const onCreateCampsite = /* GraphQL */ `
  subscription OnCreateCampsite {
    onCreateCampsite {
      id
      type
      name
      content
      state
      popular
      hits
      loadBucket
      location
      Rating
      createdAt
      updatedAt
      MultiBoard {
        items {
          id
          user
          phone
          createdAt
          updatedAt
          name
          CampsiteID
          loadBucket
          startdate
          enddate
          nights
          content
          ttl
          hits
          dates
        }
        nextToken
      }
    }
  }
`;
export const onUpdateCampsite = /* GraphQL */ `
  subscription OnUpdateCampsite {
    onUpdateCampsite {
      id
      type
      name
      content
      state
      popular
      hits
      loadBucket
      location
      Rating
      createdAt
      updatedAt
      MultiBoard {
        items {
          id
          user
          phone
          createdAt
          updatedAt
          name
          CampsiteID
          loadBucket
          startdate
          enddate
          nights
          content
          ttl
          hits
          dates
        }
        nextToken
      }
    }
  }
`;
export const onDeleteCampsite = /* GraphQL */ `
  subscription OnDeleteCampsite {
    onDeleteCampsite {
      id
      type
      name
      content
      state
      popular
      hits
      loadBucket
      location
      Rating
      createdAt
      updatedAt
      MultiBoard {
        items {
          id
          user
          phone
          createdAt
          updatedAt
          name
          CampsiteID
          loadBucket
          startdate
          enddate
          nights
          content
          ttl
          hits
          dates
        }
        nextToken
      }
    }
  }
`;
export const onCreateCampsiteRequest = /* GraphQL */ `
  subscription OnCreateCampsiteRequest {
    onCreateCampsiteRequest {
      id
      user
      phone
      createdAt
      updatedAt
      name
      CampsiteID
      loadBucket
      startdate
      enddate
      nights
      content
      ttl
      hits
      dates
      Campsite {
        id
        type
        name
        content
        state
        popular
        hits
        loadBucket
        location
        Rating
        createdAt
        updatedAt
        MultiBoard {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateCampsiteRequest = /* GraphQL */ `
  subscription OnUpdateCampsiteRequest {
    onUpdateCampsiteRequest {
      id
      user
      phone
      createdAt
      updatedAt
      name
      CampsiteID
      loadBucket
      startdate
      enddate
      nights
      content
      ttl
      hits
      dates
      Campsite {
        id
        type
        name
        content
        state
        popular
        hits
        loadBucket
        location
        Rating
        createdAt
        updatedAt
        MultiBoard {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteCampsiteRequest = /* GraphQL */ `
  subscription OnDeleteCampsiteRequest {
    onDeleteCampsiteRequest {
      id
      user
      phone
      createdAt
      updatedAt
      name
      CampsiteID
      loadBucket
      startdate
      enddate
      nights
      content
      ttl
      hits
      dates
      Campsite {
        id
        type
        name
        content
        state
        popular
        hits
        loadBucket
        location
        Rating
        createdAt
        updatedAt
        MultiBoard {
          nextToken
        }
      }
    }
  }
`;
export const onCreateFoundResult = /* GraphQL */ `
  subscription OnCreateFoundResult {
    onCreateFoundResult {
      id
      user
      content
      ttl
      count
      createdAt
      updatedAt
      CSID
      Campsite {
        id
        type
        name
        content
        state
        popular
        hits
        loadBucket
        location
        Rating
        createdAt
        updatedAt
        MultiBoard {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateFoundResult = /* GraphQL */ `
  subscription OnUpdateFoundResult {
    onUpdateFoundResult {
      id
      user
      content
      ttl
      count
      createdAt
      updatedAt
      CSID
      Campsite {
        id
        type
        name
        content
        state
        popular
        hits
        loadBucket
        location
        Rating
        createdAt
        updatedAt
        MultiBoard {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteFoundResult = /* GraphQL */ `
  subscription OnDeleteFoundResult {
    onDeleteFoundResult {
      id
      user
      content
      ttl
      count
      createdAt
      updatedAt
      CSID
      Campsite {
        id
        type
        name
        content
        state
        popular
        hits
        loadBucket
        location
        Rating
        createdAt
        updatedAt
        MultiBoard {
          nextToken
        }
      }
    }
  }
`;
export const onCreateVacency = /* GraphQL */ `
  subscription OnCreateVacency {
    onCreateVacency {
      id
      ttl
      siteName
      Date
      createdAt
      updatedAt
      popular
      campSiteName
      CSID
      Campsite {
        id
        type
        name
        content
        state
        popular
        hits
        loadBucket
        location
        Rating
        createdAt
        updatedAt
        MultiBoard {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateVacency = /* GraphQL */ `
  subscription OnUpdateVacency {
    onUpdateVacency {
      id
      ttl
      siteName
      Date
      createdAt
      updatedAt
      popular
      campSiteName
      CSID
      Campsite {
        id
        type
        name
        content
        state
        popular
        hits
        loadBucket
        location
        Rating
        createdAt
        updatedAt
        MultiBoard {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteVacency = /* GraphQL */ `
  subscription OnDeleteVacency {
    onDeleteVacency {
      id
      ttl
      siteName
      Date
      createdAt
      updatedAt
      popular
      campSiteName
      CSID
      Campsite {
        id
        type
        name
        content
        state
        popular
        hits
        loadBucket
        location
        Rating
        createdAt
        updatedAt
        MultiBoard {
          nextToken
        }
      }
    }
  }
`;
