import React from 'react';
import './index.css';
import App from './App';
import Term from './Terms';
import Privacy  from './Privacy';
import Donate  from './Donate';

import reportWebVitals from './reportWebVitals';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route exact path='/' element={< App />}></Route>
        <Route exact path='/terms' element={< Term />}></Route>
        <Route exact path='/privacy' element={< Privacy />}></Route>
        <Route exact path='/donate' element={< Donate />}></Route>

      </Routes>
    </Router>


  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
