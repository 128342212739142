/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCampsite = /* GraphQL */ `
  query GetCampsite($id: ID!) {
    getCampsite(id: $id) {
      id
      type
      name
      content
      state
      popular
      hits
      loadBucket
      location
      Rating
      createdAt
      updatedAt
      MultiBoard {
        items {
          id
          user
          phone
          createdAt
          updatedAt
          name
          CampsiteID
          loadBucket
          startdate
          enddate
          nights
          content
          ttl
          hits
          dates
        }
        nextToken
      }
    }
  }
`;
export const listCampsites = /* GraphQL */ `
  query ListCampsites(
    $filter: ModelCampsiteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCampsites(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        name
        content
        state
        popular
        hits
        loadBucket
        location
        Rating
        createdAt
        updatedAt
        MultiBoard {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getCampsiteRequest = /* GraphQL */ `
  query GetCampsiteRequest($id: ID!) {
    getCampsiteRequest(id: $id) {
      id
      user
      phone
      createdAt
      updatedAt
      name
      CampsiteID
      loadBucket
      startdate
      enddate
      nights
      content
      ttl
      hits
      dates
      Campsite {
        id
        type
        name
        content
        state
        popular
        hits
        loadBucket
        location
        Rating
        createdAt
        updatedAt
        MultiBoard {
          nextToken
        }
      }
    }
  }
`;
export const listCampsiteRequests = /* GraphQL */ `
  query ListCampsiteRequests(
    $filter: ModelCampsiteRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCampsiteRequests(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user
        phone
        createdAt
        updatedAt
        name
        CampsiteID
        loadBucket
        startdate
        enddate
        nights
        content
        ttl
        hits
        dates
        Campsite {
          id
          type
          name
          content
          state
          popular
          hits
          loadBucket
          location
          Rating
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const queryByOwner = /* GraphQL */ `
  query QueryByOwner(
    $user: String
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCampsiteRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryByOwner(
      user: $user
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user
        phone
        createdAt
        updatedAt
        name
        CampsiteID
        loadBucket
        startdate
        enddate
        nights
        content
        ttl
        hits
        dates
        Campsite {
          id
          type
          name
          content
          state
          popular
          hits
          loadBucket
          location
          Rating
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const queryByLoadBucket = /* GraphQL */ `
  query QueryByLoadBucket(
    $loadBucket: Int
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCampsiteRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryByLoadBucket(
      loadBucket: $loadBucket
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user
        phone
        createdAt
        updatedAt
        name
        CampsiteID
        loadBucket
        startdate
        enddate
        nights
        content
        ttl
        hits
        dates
        Campsite {
          id
          type
          name
          content
          state
          popular
          hits
          loadBucket
          location
          Rating
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const byCampSite = /* GraphQL */ `
  query ByCampSite(
    $CampsiteID: ID
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCampsiteRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byCampSite(
      CampsiteID: $CampsiteID
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user
        phone
        createdAt
        updatedAt
        name
        CampsiteID
        loadBucket
        startdate
        enddate
        nights
        content
        ttl
        hits
        dates
        Campsite {
          id
          type
          name
          content
          state
          popular
          hits
          loadBucket
          location
          Rating
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getVacency = /* GraphQL */ `
  query GetVacency($id: ID!) {
    getVacency(id: $id) {
      id
      ttl
      siteName
      Date
      createdAt
      updatedAt
      popular
      campSiteName
      CSID
      Campsite {
        id
        type
        name
        content
        state
        popular
        hits
        loadBucket
        location
        Rating
        createdAt
        updatedAt
        MultiBoard {
          nextToken
        }
      }
    }
  }
`;
export const listVacencys = /* GraphQL */ `
  query ListVacencys(
    $filter: ModelVacencyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVacencys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ttl
        siteName
        Date
        createdAt
        updatedAt
        popular
        campSiteName
        CSID
        Campsite {
          id
          type
          name
          content
          state
          popular
          hits
          loadBucket
          location
          Rating
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const queryvbyCSNAme = /* GraphQL */ `
  query QueryvbyCSNAme(
    $campSiteName: String
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelVacencyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryvbyCSNAme(
      campSiteName: $campSiteName
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ttl
        siteName
        Date
        createdAt
        updatedAt
        popular
        campSiteName
        CSID
        Campsite {
          id
          type
          name
          content
          state
          popular
          hits
          loadBucket
          location
          Rating
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const queryvbypopular = /* GraphQL */ `
  query Queryvbypopular(
    $popular: String
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelVacencyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryvbypopular(
      popular: $popular
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ttl
        siteName
        Date
        createdAt
        updatedAt
        popular
        campSiteName
        CSID
        Campsite {
          id
          type
          name
          content
          state
          popular
          hits
          loadBucket
          location
          Rating
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getFoundResult = /* GraphQL */ `
  query GetFoundResult($id: ID!) {
    getFoundResult(id: $id) {
      id
      user
      content
      ttl
      count
      createdAt
      updatedAt
      CSID
      Campsite {
        id
        type
        name
        content
        state
        popular
        hits
        loadBucket
        location
        Rating
        createdAt
        updatedAt
        MultiBoard {
          nextToken
        }
      }
    }
  }
`;
export const listFoundResults = /* GraphQL */ `
  query ListFoundResults(
    $filter: ModelFoundResultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFoundResults(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user
        content
        ttl
        count
        createdAt
        updatedAt
        CSID
        Campsite {
          id
          type
          name
          content
          state
          popular
          hits
          loadBucket
          location
          Rating
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const queryByFoundUser = /* GraphQL */ `
  query QueryByFoundUser(
    $user: String
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFoundResultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryByFoundUser(
      user: $user
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user
        content
        ttl
        count
        createdAt
        updatedAt
        CSID
        Campsite {
          id
          type
          name
          content
          state
          popular
          hits
          loadBucket
          location
          Rating
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
