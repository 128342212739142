import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';

import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { Loader } from "@googlemaps/js-api-loader";
import * as ib from "./ibdata"
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import SearchIcon from '@material-ui/icons/Search';

const California = { lat: 37.0902, lng: -95.7129 }
let count = 20
let searching = {}
const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    map: {
        width: "100%",
        height: 400,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));
var map
// var locations = {}
var avail = {}
var markers = []
var allData = {}
var allSites = {}

export default function ShowMapDialog(props) {
    const classes = useStyles();
    const [place, setPlace] = React.useState(null)
    const handleClose = () => {
        map = null
        if (props.cb)
            props.cb(null);
    };

    function setupCalendarData() {
        let oneweek = new Date(Date.now() + 1000 * 60 * 60 * 24 * 7)

        ib.listVacencys(null, gotData)
        allData = {}
        avail = {}
        function gotData(dat) {
            if (!dat) return finishData(oneweek)
            dat.forEach(d => {
                d.avail = JSON.parse(d.siteName)
                if (!(d.Campsite.id in allSites)) {
                    var f = JSON.parse(d.Campsite.content)
                    d.Campsite.Content = f
                    delete (d.Campsite.content)
                    allSites[d.Campsite.id] = d.Campsite
                }
                delete (d.siteName)
                allData[d.id] = d
            })
        }
    }

    function finishData(dateEnd) {
        avail = {}
        Object.keys(allData).forEach(a1 => {
            let a = allData[a1]
            a.avail.forEach(d => {
                let avDate = new Date(d.date)
                if (avDate < dateEnd) {
                    if (!avail[a.Campsite.id]) {
                        avail[a.Campsite.id] = {}
                    }
                    avail[a.Campsite.id][d.date] = d
                }
            })
        })
    }


    const [formValues, setformValues] = React.useState({ location: "", miles: "20", available: "all" })
    function valueChange(arg) {
        let val = arg.target.value
        let name = arg.target.name

        setformValues(p => {
            return ({
                ...p,
                [name]: val
            })
        })
    }
    React.useEffect(() => {
        if (map) return
        setupCalendarData()
        let loader = new Loader({
            apiKey: "AIzaSyBAkENU4qa9Pf-QrF7Tyxaof9LVGpxIGdk",
            version: "weekly",
            libraries: ["places"]
        })
        loader.load().then(() => {
            loadMap(California)
            findLocations()

        }).catch((e) => {
            console.error(e)
        })
    }, [])

    const [center, setCenter] = React.useState(California)
    function searchClick() {
        if (formValues.location.length > 0) {
            searchLocation(formValues.location, foundLoc, null)

            function foundLoc(loc, name) {
                if (loc) {
                    let ll = loc.geometry.location
                    let lat = ll.lat()
                    let lng = ll.lng()
                    deleteMarkers()
                    findCrowFlies(lat, lng)
                    setCenter({ lat: lat, lng: lng })
                    // find all places and create markers
                }
            }
        }
    }
    function toRad(Value) {
        return Value * Math.PI / 180;
    }
    function calcCrow(lat1, lon1, lat2, lon2) {
        var R = 6371; // km
        var dLat = toRad(lat2 - lat1);
        var dLon = toRad(lon2 - lon1);
        var lat1 = toRad(lat1);
        var lat2 = toRad(lat2);

        var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        var d = R * c;
        return d * 0.621371;
    }

    function findCrowFlies(lat, lng) {
        var places = []
        let cs = props.camps

        if (formValues.available !== "all") {
            let week = parseInt(formValues.available)
            let oneweek = new Date(Date.now() + 1000 * 60 * 60 * 24 * 7 * week)
            finishData(oneweek)
        }

        let selDist = parseInt(formValues.miles)
        cs.forEach(c => {
            if (c.location && c.location.length > 0) {
                let l = JSON.parse(c.location)
                let dist = calcCrow(l.lat, l.lng, lat, lng)
                if (dist < selDist) {
                    //filter available
                    if (formValues.available !== "all") {
                        if (!avail[c.id]) {
                            return
                        }
                    }
                    places.push(c)
                    addMarker({ position: l, place: c })
                }
            }
        })
        return places
    }

    const zoom = 4;

    function loadMap(cent) {
        if (map) {
            map.panTo(cent)

            let bounds = new window.google.maps.LatLngBounds();
            markers.forEach((mark) => {
                let loc = new window.google.maps.LatLng(mark.position.lat(), mark.position.lng());
                bounds.extend(loc);
            })
            map.fitBounds(bounds);
            map.panToBounds(bounds);

            return
        }
        if (!ref || !ref.current || map) return
        map = new window.google.maps.Map(ref.current, {
            cent,
            zoom,
        });
        if (markers.length > 0) {
            let bounds = new window.google.maps.LatLngBounds();

            markers.forEach((mark) => {
                let loc = new window.google.maps.LatLng(mark.position.lat(), mark.position.lng());
                bounds.extend(loc);
            })
            map.fitBounds(bounds);
            map.panToBounds(bounds);
        }
    }
    const ref = React.useRef(null);

    React.useEffect(() => {
        if (center) {
            loadMap(center)
        }
    }, [center]);

    // Adds a marker to the map and push to the array.
    function addMarker(mark) {
        let position = mark.position
        const marker = new window.google.maps.Marker({
            position,
            map,
        });
        window.google.maps.event.addListener(marker, "click", () => {
            setPlace(mark.place)
        });
        markers.push(marker);
    }

    // Sets the map on all markers in the array.
    function setMapOnAll(map) {
        for (let i = 0; i < markers.length; i++) {
            markers[i].setMap(map);
        }
    }

    // Removes the markers from the map, but keeps them in the array.
    function hideMarkers() {
        setMapOnAll(null);
    }

    // Shows any markers currently in the array.
    function showMarkers() {
        setMapOnAll(map);
    }

    // Deletes all markers in the array by removing references to them.
    function deleteMarkers() {
        hideMarkers();
        markers = [];
    }

    function createMarker(place) {
        let google = window.google
        if (!place.geometry || !place.geometry.location) return;
        const marker = new google.maps.Marker({
            map,
            position: place.geometry.location,
        });
        google.maps.event.addListener(marker, "click", () => {
            console.log("CLICJ", place.name)
        });
    }

    function findLocations() {
        let cs = props.camps
        cs.forEach(c => {
            if (c.location && c.location.length > 0) {
                // console.log("ALRADY LOCATION", c)
                return
            }
            if (searching[c.id]) {
                return
            }

            count--
            if (count > 0) {
                searching[c.id] = true
                let cont = c.content
                let searchName = c.name
                if (cont && cont.keywords && cont.keywords.length > 0) {
                    // searchName = cont.keywords
                }
                if (c.id.includes("Rec_")) {
                    let desc = cont.description
                    let b = desc.split("</h2>")[1]
                    if (b) {
                        b = b.split(" is")[0]
                        b = b.replace("\n", "")
                        b = b.replace("<p>", "")

                        if (b.length > 0 && b.length < 30)
                            searchName = b
                    }
                }
                searchName = searchName.split("-")[0]
                searchName = searchName.replace("SP", "State Park")
                searchName = searchName.replace("SB", "State Beach")
                searchName = searchName.replace("SRA", "State Park")
                console.log("DOING SEARCH FOR", searchName)
                searchLocation(searchName, foundCampLocation, JSON.parse(JSON.stringify(c)))
            }
        })

    }

    function foundCampLocation(loc, cname, cs) {
        if (loc) {
            let ll = loc.geometry.location
            let lat = ll.lat()
            let lng = ll.lng()
            console.log("FOUND LOCATION FOR ", cname, lat, lng, cs, loc)
            let cmd = JSON.parse(JSON.stringify(cs))
            cmd.location = JSON.stringify({ lat: lat, lng: lng })
            cmd.Rating = "" + loc.rating
            delete cmd['content']
            delete cmd['keywords']
            delete cmd['img']
            delete cmd['url']
            delete cmd['tableData']

            ib.updateCampsite(cmd)
        }
    }
    function searchLocation(c, cb, cs) {
        let google = window.google
        const request = {
            query: c,
            fields: ["name", "geometry", "rating"],
            // type:["campground", "lodging", "tourist_attraction" , "natural_feature",  "park", "point_of_interest", "establishment"],
            // locationBias: {radius: 600, center: {lat: 36.7783, lng: -119.4179}}

        };
        let service = new window.google.maps.places.PlacesService(map);
        service.findPlaceFromQuery(request, (results, status) => {
            if (status === google.maps.places.PlacesServiceStatus.OK && results && results.length > 0) {
                // for (let i = 0; i < results.length; i++) {
                cb(results[0], c, cs)
                // }
                // map.setCenter(results[0].geometry.location);
            } else {
                console.log("STATUS IS", status, c, cs)
            }

        });
    }
    return (
        <Dialog open={props.open} maxWidth="sm" fullWidth onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
                Search By Location
            </DialogTitle>
            <CloseIcon style={{
                backgroundColor: "#E9EBF0", color: "rgb(47, 61, 91)", cursor: "pointer", borderRadius: "50%", position: "absolute", right: "35px", top: "18px",
            }} onClick={handleClose} />
            <DialogContent>
                <>
                    <DialogContentText>

                        <Grid container direction="column">
                            <Grid item>
                                <div className="mapcontrol">

                                    <TextField
                                        id="standard-helperText"
                                        label="Search Location"
                                        name="location"
                                        onChange={(event) => valueChange(event)}
                                        value={formValues.location}
                                        helperText="Location to center map"
                                    />
                                    <FormControl className={classes.formControl}>
                                        <InputLabel id="demo-simple-select-helper-label">Miles</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            name="miles"
                                            value={formValues.miles}
                                            onChange={valueChange}
                                        >
                                            <MenuItem value={"10"}>10</MenuItem>
                                            <MenuItem value={"20"}>20</MenuItem>
                                            <MenuItem value={"50"}>50</MenuItem>
                                            <MenuItem value={"100"}>100</MenuItem>
                                            <MenuItem value={"500"}>500</MenuItem>
                                            <MenuItem value={"1000"}>1000</MenuItem>
                                        </Select>
                                        <FormHelperText>Miles as crow flies</FormHelperText>
                                    </FormControl>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel id="demo-simple-select-helper-label">Availability</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            name="available"
                                            value={formValues.available}
                                            onChange={valueChange}
                                        >
                                            <MenuItem value={"1"}>1 Week</MenuItem>
                                            <MenuItem value={"2"}>2 Weeks</MenuItem>
                                            <MenuItem value={"4"}>4 Weeks</MenuItem>
                                            <MenuItem value={"all"}>Any</MenuItem>
                                        </Select>
                                        <FormHelperText>Availabile in next</FormHelperText>
                                    </FormControl>
                                    <Button
                                        onClick={searchClick}
                                        color="primary"
                                        variant="contained"
                                        style={{ textTransform: 'none' }}
                                        size="small"
                                        startIcon={<SearchIcon />}
                                    >
                                        Search
                                    </Button>
                                </div>

                            </Grid>

                            <Grid item>
                                <div className={classes.map} ref={ref} id="map" />
                            </Grid>

                            <Grid item>
                                {place && (

                                    <div className="mapInfo" id="mapInfo" >
                                        <Grid direction="row" container>

                                            <Grid item xs={8}>
                                                <a href={place.url} target="_blank">
                                                    {place.name}
                                                </a>
                                            </Grid>
                                            <Grid item>
                                                Rating/Polularity:
                                                {place.Rating}/{place.popular}
                                            </Grid>
                                            <Grid item xs={8}>
                                                <img src={place.img} style={{ width: 120, borderRadius: '5%' }} />
                                            </Grid>
                                        </Grid>

                                    </div>
                                )}

                            </Grid>
                        </Grid>
                    </DialogContentText>

                </>
            </DialogContent>
        </Dialog >

    )
}