import React from 'react';
import AddSite from './AddSite';
import * as ib from './ibdata';
import { CampTable } from './CampTable';
import { MyAppBar } from './AppBar'
import MyNotif from './MyNotif';
import CalendarAvail from './CalendarAvail';
import ShowMapDialog from './ShowMapDialog';
import FoundReq from './FoundReq';
import { Button, Grid, Typography } from '@material-ui/core';
var gCamp = []
export default function MainPage(props) {
    const [addSite, setAddSite] = React.useState({ open: false, cb: null })
    const [camps, setCamps] = React.useState([]);
    const [myFoundResult, setFoundResult] = React.useState([]);
    const [siteFoundCount, setSiteFoundCount] = React.useState(0);
    const [myReq, setmyReq] = React.useState([])
    const [reqCount, setReqCount] = React.useState(0);

    React.useEffect(() => {
        ib.subscribeCampsite(gotcamp)
        function gotcamp(dat) {
            let ff = [...gCamp];
            for (let j = 0; j < dat.length; j++) {
                var n = dat[j]
                for (var i = 0; i < ff.length; i++) {
                    var c = ff[i]
                    if (c.id === n.id) {
                        ff.splice(i, 1)
                    }
                }
                try {
                    n.content = JSON.parse(n.content)
                    n.keywords = n.content.keywords
                    n.img = n.content.img
                    if (n.id.includes("Rec_")) {
                        n.url = "https://www.recreation.gov/camping/campgrounds/" + n.content.id
                    }
                    if (n.id.includes("RecP_")) {
                        n.url = "https://www.recreation.gov/permits/" + n.content.id
                    }
                    if (n.id.includes("RESV_")) {
                        n.url = "https://www.parks.ca.gov/?page_id=" + n.content.linkId
                    }
                    if (n.id.includes("SON_")) {
                        n.url = "https://reserve.sonomacountycamping.org/" + n.content.linkId + "/camping/"
                    }
                } catch { }
                ff.push(n)
            }
            ff.sort((a, b) => {
                if (a.popular && b.popular) {
                    if (a.popular < b.popular) return 1
                    else return -1
                }
                return 0
            })
            gCamp = ff
            setCamps(ff);
        }
       ib.subscribeFoundResults(gotFound)
        function gotFound(dat) {
            let ff = [...myFoundResult];
            var newc = 0;
            for (let j = 0; j < dat.length; j++) {
                var n = dat[j]
                // console.log("N IS", n) 
                if (!n) continue
                const idx = ff.findIndex(item => item.id === n.id)
                if (idx === -1) {
                    try {
                        n.content = JSON.parse(n.content)
                        let p = n.Campsite
                        p.content = JSON.parse(n.Campsite.content)
                        n.img = p.content.img
                        if (p.id.includes("Rec_")) {
                            n.url = "https://www.recreation.gov/camping/campgrounds/" + p.content.id
                        }
                        if (n.id.includes("RecP_")) {
                            n.url = "https://www.recreation.gov/permits/" + n.content.id
                        }
                        if (p.id.includes("RESV_")) {
                            n.url = "https://www.parks.ca.gov/?page_id=" + p.content.linkId
                        }
                        if (p.id.includes("SON_")) {
                            n.url = "https://reserve.sonomacountycamping.org/" + p.content.linkId + "/camping/"
                        }
                        n.resvName = n.content.resvName
                        n.foundName = p.name
                        n.dt = new Date(n.createdAt)
                    } catch {

                    }
                    setFoundResult(list => [...list, n])

                    newc += n.count
                }
            }
            setSiteFoundCount(old => old + newc)
        }

        ib.subscribeCampRequests(gotData2, delcb);
        function delcb(n) {
            setmyReq(list => list.filter(item => item.id !== n.id));
        }
        function gotData2(dat) {
            let ff = [...myReq];
            var newc = 0;
            for (let j = 0; j < dat.length; j++) {
                var n = dat[j]
                const idx = ff.findIndex(item => item.id === n.id)
                if (idx === -1) {
                    setmyReq(list => [...list, n])
                    newc += 1
                }
            }
            setReqCount(old => old + newc)
        }
    }, []);
    function AddNewSite() {
        setAddSite({ open: true, cb: done });
        function done(e) {
            setAddSite({ open: false });
        }
    }
    const [sNotif, setNotif] = React.useState({ open: false, cb: null })

    function showNotif(data) {
        setNotif({ open: true, cb: done, data: data });
        function done(e) {
            setNotif({ open: false });
        }
    }

    const [sCalendarAvail, setsCalendarAvail] = React.useState({ open: false, cb: null })

    function showCalendar(data) {
        setsCalendarAvail({ open: true, cb: done, data: data });
        function done(e) {
            setsCalendarAvail({ open: false });
        }
    }

    const [showMap, setShowMap] = React.useState({ open: false, cb: null })

    function showMapCB(data) {
        setShowMap({ open: true, cb: done, data: data });
        function done(e) {
            setShowMap({ open: false });
        }
    }
    const [smsObj, setSMSObj] = React.useState({ open: false, cb: null })

    async function smsShow() {
        setSMSObj({ open: true, cb: done, data: myFoundResult });
        function done(e) {
            setSMSObj({ open: false });
        }
    }
    return (
        <div>
            <MyAppBar new={AddNewSite} showNotif={showNotif} smsShow={smsShow} />

            <Grid container justify="space-around">
                <Grid item>
                    <Button color="secondary" variant="contained" onClick={() => smsShow()}>
                        {siteFoundCount > 0 && (
                            <div className="box">

                                <Typography variant="h5">
                                    {siteFoundCount}
                                </Typography>
                                <Typography variant="h7">
                                    CampSites Found
                                </Typography>
                            </div>

                        )}

                    </Button>
                </Grid>
                <Grid item>
                    <Button color="secondary" variant="contained" onClick={() => showCalendar(null)}>
                        <div className="box">
                            <Typography variant="h6">
                                Campsite <br /> Calendar
                            </Typography>
                        </div>

                    </Button>
                </Grid>
                <Grid item>
                    <Button color="secondary" variant="contained" onClick={() => showMapCB(null)}>
                        <div className="box">
                            <Typography variant="h6">
                                Search by <br /> Location
                            </Typography>
                        </div>

                    </Button>
                </Grid>
                <Grid item>
                    <Button color="secondary" variant="contained" onClick={() => showNotif(null)}>
                        {reqCount > 0 && (
                            <div className="box">

                                <Typography variant="h6">
                                    {reqCount}
                                </Typography>
                                <Typography variant="h7">
                                    Searches Running
                                </Typography>
                            </div>

                        )}


                    </Button>
                </Grid>

            </Grid>
            

            {addSite.open && (<AddSite {...addSite} />)}
            {sNotif.open && (<MyNotif {...sNotif} />)}
            {sCalendarAvail.open && (<CalendarAvail {...sCalendarAvail} />)}
            {showMap.open && (<ShowMapDialog {...showMap} camps={camps} />)}

            {smsObj.open && (<FoundReq {...smsObj} />)}

            <CampTable data={camps} showNotif={showNotif} />
        </div>
    )
}
