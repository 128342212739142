/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCampsite = /* GraphQL */ `
  mutation CreateCampsite(
    $input: CreateCampsiteInput!
    $condition: ModelCampsiteConditionInput
  ) {
    createCampsite(input: $input, condition: $condition) {
      id
      type
      name
      content
      state
      popular
      hits
      loadBucket
      location
      Rating
      createdAt
      updatedAt
      MultiBoard {
        items {
          id
          user
          phone
          createdAt
          updatedAt
          name
          CampsiteID
          loadBucket
          startdate
          enddate
          nights
          content
          ttl
          hits
          dates
        }
        nextToken
      }
    }
  }
`;
export const updateCampsite = /* GraphQL */ `
  mutation UpdateCampsite(
    $input: UpdateCampsiteInput!
    $condition: ModelCampsiteConditionInput
  ) {
    updateCampsite(input: $input, condition: $condition) {
      id
      type
      name
      content
      state
      popular
      hits
      loadBucket
      location
      Rating
      createdAt
      updatedAt
      MultiBoard {
        items {
          id
          user
          phone
          createdAt
          updatedAt
          name
          CampsiteID
          loadBucket
          startdate
          enddate
          nights
          content
          ttl
          hits
          dates
        }
        nextToken
      }
    }
  }
`;
export const deleteCampsite = /* GraphQL */ `
  mutation DeleteCampsite(
    $input: DeleteCampsiteInput!
    $condition: ModelCampsiteConditionInput
  ) {
    deleteCampsite(input: $input, condition: $condition) {
      id
      type
      name
      content
      state
      popular
      hits
      loadBucket
      location
      Rating
      createdAt
      updatedAt
      MultiBoard {
        items {
          id
          user
          phone
          createdAt
          updatedAt
          name
          CampsiteID
          loadBucket
          startdate
          enddate
          nights
          content
          ttl
          hits
          dates
        }
        nextToken
      }
    }
  }
`;
export const createCampsiteRequest = /* GraphQL */ `
  mutation CreateCampsiteRequest(
    $input: CreateCampsiteRequestInput!
    $condition: ModelCampsiteRequestConditionInput
  ) {
    createCampsiteRequest(input: $input, condition: $condition) {
      id
      user
      phone
      createdAt
      updatedAt
      name
      CampsiteID
      loadBucket
      startdate
      enddate
      nights
      content
      ttl
      hits
      dates
      Campsite {
        id
        type
        name
        content
        state
        popular
        hits
        loadBucket
        location
        Rating
        createdAt
        updatedAt
        MultiBoard {
          nextToken
        }
      }
    }
  }
`;
export const updateCampsiteRequest = /* GraphQL */ `
  mutation UpdateCampsiteRequest(
    $input: UpdateCampsiteRequestInput!
    $condition: ModelCampsiteRequestConditionInput
  ) {
    updateCampsiteRequest(input: $input, condition: $condition) {
      id
      user
      phone
      createdAt
      updatedAt
      name
      CampsiteID
      loadBucket
      startdate
      enddate
      nights
      content
      ttl
      hits
      dates
      Campsite {
        id
        type
        name
        content
        state
        popular
        hits
        loadBucket
        location
        Rating
        createdAt
        updatedAt
        MultiBoard {
          nextToken
        }
      }
    }
  }
`;
export const deleteCampsiteRequest = /* GraphQL */ `
  mutation DeleteCampsiteRequest(
    $input: DeleteCampsiteRequestInput!
    $condition: ModelCampsiteRequestConditionInput
  ) {
    deleteCampsiteRequest(input: $input, condition: $condition) {
      id
      user
      phone
      createdAt
      updatedAt
      name
      CampsiteID
      loadBucket
      startdate
      enddate
      nights
      content
      ttl
      hits
      dates
      Campsite {
        id
        type
        name
        content
        state
        popular
        hits
        loadBucket
        location
        Rating
        createdAt
        updatedAt
        MultiBoard {
          nextToken
        }
      }
    }
  }
`;
export const createVacency = /* GraphQL */ `
  mutation CreateVacency(
    $input: CreateVacencyInput!
    $condition: ModelVacencyConditionInput
  ) {
    createVacency(input: $input, condition: $condition) {
      id
      ttl
      siteName
      Date
      createdAt
      updatedAt
      popular
      campSiteName
      CSID
      Campsite {
        id
        type
        name
        content
        state
        popular
        hits
        loadBucket
        location
        Rating
        createdAt
        updatedAt
        MultiBoard {
          nextToken
        }
      }
    }
  }
`;
export const updateVacency = /* GraphQL */ `
  mutation UpdateVacency(
    $input: UpdateVacencyInput!
    $condition: ModelVacencyConditionInput
  ) {
    updateVacency(input: $input, condition: $condition) {
      id
      ttl
      siteName
      Date
      createdAt
      updatedAt
      popular
      campSiteName
      CSID
      Campsite {
        id
        type
        name
        content
        state
        popular
        hits
        loadBucket
        location
        Rating
        createdAt
        updatedAt
        MultiBoard {
          nextToken
        }
      }
    }
  }
`;
export const deleteVacency = /* GraphQL */ `
  mutation DeleteVacency(
    $input: DeleteVacencyInput!
    $condition: ModelVacencyConditionInput
  ) {
    deleteVacency(input: $input, condition: $condition) {
      id
      ttl
      siteName
      Date
      createdAt
      updatedAt
      popular
      campSiteName
      CSID
      Campsite {
        id
        type
        name
        content
        state
        popular
        hits
        loadBucket
        location
        Rating
        createdAt
        updatedAt
        MultiBoard {
          nextToken
        }
      }
    }
  }
`;
export const createFoundResult = /* GraphQL */ `
  mutation CreateFoundResult(
    $input: CreateFoundResultInput!
    $condition: ModelFoundResultConditionInput
  ) {
    createFoundResult(input: $input, condition: $condition) {
      id
      user
      content
      ttl
      count
      createdAt
      updatedAt
      CSID
      Campsite {
        id
        type
        name
        content
        state
        popular
        hits
        loadBucket
        location
        Rating
        createdAt
        updatedAt
        MultiBoard {
          nextToken
        }
      }
    }
  }
`;
export const updateFoundResult = /* GraphQL */ `
  mutation UpdateFoundResult(
    $input: UpdateFoundResultInput!
    $condition: ModelFoundResultConditionInput
  ) {
    updateFoundResult(input: $input, condition: $condition) {
      id
      user
      content
      ttl
      count
      createdAt
      updatedAt
      CSID
      Campsite {
        id
        type
        name
        content
        state
        popular
        hits
        loadBucket
        location
        Rating
        createdAt
        updatedAt
        MultiBoard {
          nextToken
        }
      }
    }
  }
`;
export const deleteFoundResult = /* GraphQL */ `
  mutation DeleteFoundResult(
    $input: DeleteFoundResultInput!
    $condition: ModelFoundResultConditionInput
  ) {
    deleteFoundResult(input: $input, condition: $condition) {
      id
      user
      content
      ttl
      count
      createdAt
      updatedAt
      CSID
      Campsite {
        id
        type
        name
        content
        state
        popular
        hits
        loadBucket
        location
        Rating
        createdAt
        updatedAt
        MultiBoard {
          nextToken
        }
      }
    }
  }
`;
