import React from 'react';
import MaterialTable from 'material-table';
import Button from '@material-ui/core/Button';
import AddCircleIcon from '@material-ui/icons/AddCircle';

export function CampTable(props) {

    function MultiSelect(data) {
        if (data.length > 5) {
            alert("Please pick only 5 sites at a time");
            return;
        }
        if (props.showNotif) props.showNotif(data);
    }
    // console.log("DATA IS", props.data)
    return (<MaterialTable
        title="Pick Sites"
        columns={[
            {
                field: 'img',
                title: 'Image',
                render: rowData => <a href={rowData.url} target="_blank">
                    <img src={rowData.img} style={{ width: 70, borderRadius: '5%' }} />
                </a>
            },
            {
                title: 'Name', field: 'name',
                render: rowData => <a href={rowData.url} target="_blank">
                    {rowData.name}
                </a>
            },
            { title: 'Active queries', field: 'popular', type: 'numeric', defaultSort: 'desc' },

            { title: 'From', field: 'type' },
            { title: 'Keywords', field: 'keywords' },

        ]}
        options={{
            selection: true,
            pageSize: 10, 
        }}
        data={props.data}
        actions={[
            {
                tooltip: 'Create new text',
                icon: "addcircle",
                onClick: (evt, data) => MultiSelect(data)
            }
        ]}
        components={{
            Action:
                props => {
                    if (props.action.icon === 'addcircle') {
                        return (
                            <Button
                                onClick={(event) => props.action.onClick(event, props.data)}
                                color="primary"
                                variant="contained"
                                style={{ textTransform: 'none' }}
                                size="small"
                                startIcon={<AddCircleIcon />}
                            >
                                Add Notification
                            </Button>
                        )
                    }
                }

        }}

    />)
}