import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MaterialTable from 'material-table';

export default function FoundReq(props) {
    const open = props.open;

    function ExistingTable(p2) {
        return (<MaterialTable
            title="Found Campsites"
            columns={[
                {
                    field: 'img',
                    title: 'Image',
                    render: rowData => <a href={rowData.url} target="_blank">
                        <img src={rowData.img} style={{ width: 40, borderRadius: '5%' }} />
                    </a>

                },
                { title: 'Label', field: 'resvName' },
                { title: 'Hits', field: 'count', type: 'numeric' },
                { title: 'Last', field: 'dt', type: "datetime", defaultSort: 'desc' },

                {
                    title: 'Campsite', field: 'foundName',
                    render: rowData => <a href={rowData.url} target="_blank">
                        {rowData.foundName}
                    </a>

                },

            ]}
            options={{
                selection: false
            }}
            data={props.data}
            actions={[
            ]}

        />)
    }

    const handleClose = () => {
        if (props.cb)
            props.cb(null);
    };


    return (
        <Dialog open={open} onClose={handleClose} maxWidth="lg" aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Campsite Requests</DialogTitle>
            <DialogContent>
                <ExistingTable />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Close
          </Button>
            </DialogActions>
        </Dialog >
    );
}
