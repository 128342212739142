import * as autoQuery from './graphql/queries'
import * as autoMutation from './graphql/mutations'
import * as autoSub from './graphql/subscriptions'
import { Auth } from 'aws-amplify';

import { API, graphqlOperation } from 'aws-amplify';


async function dummyPost(url, u, type, data) {
    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({URL: u, type: type, data: data})
    })
    var r = await response.json();
    return r
}
export function getCampsite(data, cb) {
    API.post('getSite', '/items', {
        'body': data
    }).then((res) => {
        cb(res.body)
    }).catch(error => alert("API Failed try later"))
}

export function getCamps(url, type, data, cb) {
    // var res = dummyPost("http://localhost:3500/url", url, type, data)

    // cb(res)
    API.post('getSite', '/url', {
        'body': {URL: url, type: type, data: data}
    }).then((res) => {
        cb(res.body)
    }).catch(error => alert("API Failed try later"))
}

export function createCampsite(obj) {
    return API.graphql(graphqlOperation(autoMutation.createCampsite, { input: obj }))
}

export function updateCampsite(obj) {
    delete obj['createdAt']
    delete obj['updatedAt']
    delete obj['MultiBoard']
    return API.graphql(graphqlOperation(autoMutation.updateCampsite, { input: obj }))
}

export function listCampsites(cb, nextToken) {
    const cmd = { limit: 100, nextToken: nextToken }
    API.graphql(graphqlOperation(autoQuery.listCampsites, cmd)).then(res => {
        const dat = res.data.listCampsites
        if (dat) cb(dat.items)
        if (dat.nextToken) {
            listCampsites(cb, dat.nextToken)
        }
    })
}

export function subscribeCampsite(cb) {
    listCampsites(cb, null)
    const s1 = API.graphql(graphqlOperation(autoSub.onCreateCampsite))
    var s2
    s2 = s1.subscribe({
        next: (value) => {
            const data = value.value.data.onCreateCampsite
            cb([data])
        },
        error: (error) => {
            console.log(JSON.stringify(error));
        }
    });

    return s2
}

export function createCampsiteRequest(obj) {
    obj.user = Auth.user.username;
    obj.ttl = getTTL1Week(obj) 
    return API.graphql(graphqlOperation(autoMutation.createCampsiteRequest, { input: obj }))
}

export function deleteCampsiteRequest(obj) {
    return API.graphql(graphqlOperation(autoMutation.deleteCampsiteRequest, { input: {id: obj.id} }))
}

export function listCampRequests(nextToken, cb) {
    const cmd = { limit: 100, nextToken: nextToken, user: Auth.user.username }
    const s1 = API.graphql(graphqlOperation(autoQuery.queryByOwner, cmd))
        .then(res => {
            const dat = res.data.queryByOwner
            if (dat) cb(dat.items)
            if (dat.nextToken) {
                listCampRequests(dat.nextToken, cb)
            }
        })
}

export function listFoundResults(nextToken, cb) {
    const cmd = { limit: 100, nextToken: nextToken, user: Auth.user.username}
    const s1 = API.graphql(graphqlOperation(autoQuery.queryByFoundUser, cmd))
        .then(res => {
            const dat = res.data.queryByFoundUser
            if (dat) cb(dat.items)
            if (dat.nextToken) {
                listFoundResults(dat.nextToken, cb)
            }
        })
}

export function getTTL1Week(obj) {
    const secondsSinceEpoch = Math.round(Date.now() / 1000);
    const SECONDS_IN_AN_HOUR = 60 * 60;
    const HOURS_IN_1_WEEK = 24 * 7

    // Reduced from previous 6 weeks to 4 weeks
    let expirationTime = secondsSinceEpoch + SECONDS_IN_AN_HOUR * HOURS_IN_1_WEEK * 8; //expire in 1 week
    if (obj['enddate']) {
        let dt = new Date(obj['enddate']).getTime();
        dt = dt / 1000
        expirationTime = dt + (SECONDS_IN_AN_HOUR * 24)
    }
    return expirationTime
}

export function subscribeFoundResults(cb) {
    listFoundResults(null, cb)
    const s1 = API.graphql(graphqlOperation(autoSub.subscribeToMyFoundResult, {user: Auth.user.username}))
    var s2
    s2 = s1.subscribe({
        next: (value) => {
            const data = value.value.data.subscribeToMyFoundResult
            cb([data])
        },
        error: (error) => {
            console.log(JSON.stringify(error));
        }
    });
    return s2
}

export async function listFoundResultsSync() {
    var allList = [];
    var nt = null 
    do {
        const cmd = { limit: 100, nextToken: nt, user: Auth.user.username}
        const res = await API.graphql(graphqlOperation(autoQuery.queryByFoundUser, cmd))
            const dat = res.data.queryByFoundUser
            allList = [...allList, ...dat.items]

            nt = dat.nextToken
    } while (nt!= null)
    return allList
}

export function subscribeCampRequests(cb, delcb) {
    listCampRequests(null, cb)
    const s1 = API.graphql(graphqlOperation(autoSub.subscribeToMyCamsite, {user: Auth.user.username}))
    var s2
    s2 = s1.subscribe({
        next: (value) => {
            const data = value.value.data.subscribeToMyCamsite
            cb([data])
        },
        error: (error) => {
            console.log(JSON.stringify(error));
        }
    });

    const s3 = API.graphql(graphqlOperation(autoSub.subscribeToMyCamsiteDel, {user: Auth.user.username}))
    var s4 = s3.subscribe({
        next: (value) => {
            const data = value.value.data.subscribeToMyCamsiteDel
            delcb(data)
        },
        error: (error) => {
            console.log(JSON.stringify(error));
        }
    });
    return [s2, s4] 
}

export function listVacencys(nextToken, cb) {
    const cmd = { limit: 100, nextToken: nextToken, user: Auth.user.username }
    const s1 = API.graphql(graphqlOperation(autoQuery.listVacencys, cmd))
        .then(res => {
            const dat = res.data.listVacencys
            if (dat) cb(dat.items)
            if (dat.nextToken) {
                listVacencys(dat.nextToken, cb)
            } else {
                cb(null) //finish
            }
        })
}