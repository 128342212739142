import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import * as ib from "./ibdata";

//d8031651-60ae-41e7-9a2f-81c0765867b8

function getAPI(url, cb) {
    // curl -X GET "https://ridb.recreation.gov/api/v1/facilities/232450?full=true" -H "accept: application/json" -H "apikey: d8031651-60ae-41e7-9a2f-81c0765867b8"

    let URL = "https://ridb.recreation.gov/api/v1/facilities/" + url + "?full=true"
    fetch(URL, {
        method: 'POST',
        headers: {
            mode: 'cors',
            'apikey': 'd8031651-60ae-41e7-9a2f-81c0765867b8"',
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
    })
        .then(response => response.json())
        .then((response) => cb(response))
        .catch(error => console.error(error));

}
function postData(data, cb) {
    fetch("http://localhost:3500/items", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
        .then(response => response.json())
        .then((response) => cb(response))
        .catch(error => console.error(error));
}

export default function AddSite(props) {
    const open = props.open;
    const [text, setText] = React.useState("");

    const handleClose = () => {
        if (props.cb)
            props.cb(null);
    };

    function handleSave(e) {
        if (!text) return
        let ntext = text 
        if  (text.includes("/registration/detailed-availability")) {
            ntext = text.replace("/registration/detailed-availability", "") 
        }
        var parts = ntext.split('/');
        var lastSegment = parts.pop() || parts.pop();  // handle potential trailing slash


        
        ib.getCampsite({ URL: lastSegment}, gotData)
        function gotData(dat) {
            if (dat && dat.FacilityName) {
                let img = null;
                if (dat.MEDIA) {
                    img = dat.MEDIA[0].URL
                } 
                if (!img) {
                    console.log("NO DATA", img, dat);
                    return 
                }
                var type = "Rec_"
                var kw = dat.Keywords
                if (text.includes("permits")) {
                    type = "RecP_"
                    kw = kw + ", Permits"
                }
                ib.createCampsite({
                    id: type+lastSegment, 
                    type: "Recreation.gov",
                    name: dat.FacilityName,
                    loadBucket: 3, 
                    content: JSON.stringify({
                        description: dat.FacilityDescription,
                        keywords: kw,
                        id: lastSegment, 
                        img: img
                    })
                })
            }
        }
        props.cb({ website: text });
    }
    function handleLoad(e) {
        setText(e.target.value)
    }

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Add new camping location</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Please copy the URL from Recreation.gov and paste here to add a new campsite. 
          </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Website Address"
                    type="string"
                    fullWidth
                    value={text}
                    onChange={handleLoad}

                />
            <DialogContentText>
            For California state sites email support@campsitetext.com
            </DialogContentText>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
          </Button>
                <Button onClick={handleSave} color="primary">
                    Save
          </Button>
            </DialogActions>
        </Dialog>
    );
}
