import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { Auth } from 'aws-amplify';

import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AlarmIcon from '@material-ui/icons/Alarm';
import TextsmsIcon from '@material-ui/icons/Textsms';
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
}));

export function MyAppBar(props) {
    const classes = useStyles();
    const [auth, setAuth] = React.useState(true);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleChange = (event) => {
        setAuth(event.target.checked);
    };

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
      }
    function help() {
        openInNewTab("https://bit.ly/3ofC4cT")
    }

    function signOut() {
        Auth.signOut();
        window.location.href = "/";
        window.location.reload();
    }
    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Toolbar>
                    <img src="logo512.png" alt="logo" className="logo" />
                    <IconButton edge="start" className={classes.menuButton}
                        onClick={() => {
                            if (props.new) props.new()
                        }}
                        color="inherit" aria-label="menu">
                        <AddCircleIcon />
                    </IconButton>
                    <IconButton edge="start" className={classes.menuButton}
                        onClick={() => {
                            if (props.showNotif) props.showNotif(null)
                        }}
                        color="inherit" aria-label="menu">
                        <AlarmIcon />
                    </IconButton>
                    <IconButton edge="start" className={classes.menuButton}
                        onClick={() => {
                            if (props.showNotif) props.smsShow(null)
                        }}
                        color="inherit" aria-label="SMS items">
                        <TextsmsIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        Camp Spontaneously
          </Typography>
                    {auth && (
                        <div>
                            <IconButton
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleMenu}
                                color="inherit"
                            >
                                <AccountCircle />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={open}
                                onClose={handleClose}
                            >
                                <MenuItem onClick={signOut}>Logout</MenuItem>
                                <MenuItem onClick={help}>Help</MenuItem>

                            </Menu>
                        </div>
                    )}
                </Toolbar>
            </AppBar>
        </div>
    );
}
