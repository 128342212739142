import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function TermsCondiitons(props) {

    return (
        <div className='terms'>
            <div class="markdown prose w-full break-words dark:prose-invert light"><p><strong>Text Message Consent Agreement</strong></p><p>This Text Message Consent Agreement ("Agreement") is entered into between CampsiteText.com, hereinafter referred to as the "Sender," and You, hereinafter referred to as the "Recipient."</p><p><strong>Purpose:</strong>
                The Sender and Recipient agree to establish the terms under which the Sender may send text messages to the Recipient.</p><p><strong>Terms and Conditions:</strong></p><ol><li><p>
                    <strong>Consent:</strong> By providing your mobile phone number and accepting this Agreement, the Recipient gives express consent to the Sender to send text messages to the provided phone number.</p></li><li><p><strong>Frequency:</strong> The Sender may send text messages to the Recipient for informational, promotional, or other communication purposes. The frequency of text messages will vary based on the nature of the communication and Recipient's preferences.</p></li><li><p><strong>Opt-Out:</strong> The Recipient may opt out of receiving text messages from the Sender at any time by replying "STOP" to the received text message. The Sender will promptly honor opt-out requests, and no further text messages will be sent to the Recipient after opting out.</p></li><li><p><strong>Costs:</strong> Standard messaging rates or other charges from your wireless carrier may apply to the text messages sent and received. The Sender is not responsible for any charges incurred by the Recipient while receiving text messages.</p></li><li><p><strong>Privacy:</strong> The Sender will keep all information provided by the Recipient confidential and will not share it with third parties unless required by law or with the Recipient's explicit consent.</p></li><li><p><strong>Content:</strong> Text messages may contain information related to products, services, updates, promotions, or other relevant content as determined by the Sender.</p></li><li><p><strong>No Warranty:</strong> The Sender makes no warranties regarding the accuracy, completeness, or reliability of the information provided via text messages.</p></li><li><p><strong>Termination:</strong> The Sender reserves the right to terminate this Agreement and stop sending text messages to the Recipient at any time, with or without notice.</p></li></ol><p><strong>Contact Information:</strong></p><p>Sender:
                    Email: support@campsitetext.com</p>
                       <p>By accepting this Agreement, the Recipient acknowledges that they have read and understood its terms and conditions and agree to receive text messages from the Sender.</p>
                       </div>
        </div>
    )
}